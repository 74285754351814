import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import BarChart from './BarChart';
import './Stats.scss';

class SessionDuration extends Component {
  render () {
    const {interval} = this.props;
    const labelKey = interval.toLowerCase();
    const valueKey = 'duration';
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (v)=> this.toMinutes(v),
              stepSize: 60
            }
          }
        ]
      }
    };

    return (
      <div className="SessionDuration">
        <BarChart
          className="SessionDuration"
          queryName="statsSessionDuration"
          variables={this.props}
          valueKey={valueKey}
          labelKey={labelKey}
          options={options}
        />
      </div>
    );
  }

  toMinutes = (seconds)=> {
    return Moment().startOf('day').seconds(seconds).format('mm:ss');
  }
}

SessionDuration.propTypes = {
  interval: PropTypes.string
};

export default SessionDuration;
