import React from 'react';
import PropTypes from 'prop-types';
import ChartJs from 'chart.js';
import uuidv4 from 'uuid/v4';
import './Stats.scss';

class Chart extends React.Component {
  render () {
    const {width = 400, height = 400} = this.props;
    this.id = uuidv4();
    return (
      <canvas
        className="Canvas"
        id={this.id}
        width={width}
        height={height}
      />
    );
  }

  componentDidMount () {
    const {type, data, options = {}} = this.props;
    const node = document.getElementById(this.id); // eslint-disable-line no-undef
    const context = node.getContext('2d');
    this.chart = new ChartJs(context, {type, data, options});
  }
}

Chart.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  type: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Chart;
