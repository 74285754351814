import React, {Component} from 'react';
import PropTypes from 'prop-types';

import BarChart from './BarChart';
import './Stats.scss';

class RatingsSurvey extends Component {
  render () {
    const {interval} = this.props;
    const labelKey = interval.toLowerCase();
    const valueKey = 'averageRating';
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    return (
      <div className="RatingsSurvey">
        <BarChart
          className="RatingsSurvey"
          queryName="statsRatingsSurvey"
          variables={this.props}
          valueKey={valueKey}
          labelKey={labelKey}
          options={options}
        />
      </div>
    );
  }
}

RatingsSurvey.propTypes = {
  interval: PropTypes.string
};

export default RatingsSurvey;
