import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Enums} from '@tripp/shared';
import Filter from './Filter';

class Filters extends Component {
  render () {
    const {StatsFilterType} = Enums;
    const {company} = this.props;
    const {
      seatLocations = [],
      seatGroups = []
    } = company;

    // TODO: how to handle filter existence more generally
    const $filters = [
      {
        label: 'Seat Location',
        values: seatLocations,
        type: StatsFilterType.CompanySeatLocation
      },
      {
        label: 'Seat Group',
        values: seatGroups,
        type: StatsFilterType.CompanySeatGroup
      }
    ]
      .filter(({values})=> values.length)
      .map((props)=> {
        const {type} = props;
        const selected = this.getSelected(type);
        return (
          <Filter
            {...props}
            key={type}
            selected={selected}
            onChange={this.onChange}
          />
        );
      });

    return (
      <div className="Filters">
        {$filters}
      </div>
    );
  }

  getFiltersWithType (type) {
    const {filters} = this.props;
    return filters.filter(({type: t})=> (t === type));
  }

  getFiltersWithoutType (type) {
    const {filters} = this.props;
    return filters.filter(({type: t})=> (t !== type));
  }

  getSelected (type) {
    return this.getFiltersWithType(type).map(({value})=> value);
  }

  onChange = ({type, values})=> {
    const filters = this.getFiltersWithoutType(type);
    for (const value of values) {
      filters.push({type, value});
    }
    this.props.onChange({filters});
  };
}

Filters.propTypes = {
  company: PropTypes.object,
  filters: PropTypes.array,
  onChange: PropTypes.func
};

export default Filters;
