import React from 'react';
import {StripeProvider, Elements} from 'react-stripe-elements';

import {Page} from '@tripp/shared';
import {listBillingOptions} from './BillingGQL';
import BillingForm from './BillingForm';

import './Billing.scss';

class CreateBillingPage extends React.Component {
  render () {
    const {router} = this.props;
    const apiKey = process.env.REACT_APP_STRIPE_API_KEY;
    return (
      <Page
        className="CreateBillingPage"
        query={listBillingOptions}
      >
        {({listBillingOptions: options})=> {
          return (
            <StripeProvider apiKey={apiKey}>
              <Elements>
                <BillingForm
                  options={options}
                  router={router}
                />
              </Elements>
            </StripeProvider>
          );
        }}
      </Page>
    );
  }
}

export default CreateBillingPage;
