import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {TextField} from '@rmwc/textfield';
import {Select} from '@rmwc/select';
import {Elevation} from '@rmwc/elevation';
import {IconButton} from '@rmwc/icon-button';
import DayPicker, {DateUtils} from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import './Stats.scss';

const Presets = [
  {
    label: 'today',
    startOffset: 0,
    endOffset: 0
  },
  {
    label: 'yesterday',
    startOffset: 1,
    endOffset: 1
  },
  {
    label: 'last 7 days',
    startOffset: 7,
    endOffset: 0
  },
  {
    label: 'last 14 days',
    startOffset: 14,
    endOffset: 0
  },
  {
    label: 'last 30 days',
    startOffset: 30,
    endOffset: 0
  },
  {
    label: 'last quarter',
    startOffset: 91,
    endOffset: 0
  },
  {
    label: 'last year',
    startOffset: 365,
    endOffset: 0
  }
].map((preset)=> {
  const {label, startOffset, endOffset} = preset;
  const start = Moment().subtract(startOffset, 'days');
  const end = Moment().subtract(endOffset, 'days');
  return {label, start, end};
});

const DateStrFormat = 'MM/DD/YYYY';
const DateStrRegex = /^\d{2}\/\d{2}\/\d{4}/;

function formatDate (moment) {
  return moment.format(DateStrFormat);
}

class DatePicker extends Component {
  constructor (props) {
    super(props);
    this.state = {
      calendarOpen: false,
      startStr: null,
      endStr: null
    };
  }

  render () {
    const $inputs = this.renderInputs();
    const $presets = this.renderPresets();
    const $calendar = this.renderCalendar();

    return (
      <div className="DatePicker">
        {$inputs}
        {$presets}
        {$calendar}
      </div>
    );
  }

  renderInputs () {
    const {
      start,
      end
    } = this.props;

    // Need special handling for string inputs that are being
    // edited because they may be malformed dates
    let {
      startStr,
      endStr
    } = this.state;

    if (!startStr) {
      startStr = formatDate(start);
    }

    if (!endStr) {
      endStr = formatDate(end);
    }

    return (
      <>
        <TextField
          outlined
          className="DatePickerInput"
          label="Start"
          value={startStr}
          onChange={this.setStr('start')}
        />

        <TextField
          outlined
          className="DatePickerInput"
          label="End"
          value={endStr}
          onChange={this.setStr('end')}
        />
      </>
    );
  }

  renderPresets () {
    const {props} = this;

    const options = Presets.map(({label})=> label);
    const preset = Presets.find((preset)=> {
      return ['start', 'end'].every((type)=> {
        return (formatDate(preset[type]) === formatDate(props[type]));
      });
    });

    const value = preset ? preset.label : '';

    return (
      <Select
        outlined
        className="DatePickerInput"
        label="preset"
        value={value}
        options={options}
        onChange={this.setPreset}
        placeholder="-- Preset --"
      />
    );
  }

  renderCalendar () {
    const {calendarOpen} = this.state;
    const {start, end} = this.props;

    const startDate = start.toDate();
    const endDate = end.toDate();

    const modifiers = {
      start: startDate,
      end: endDate
    };

    const selectedDays = [
      startDate,
      {from: startDate, to: endDate}
    ];

    let $body = '';
    if (calendarOpen) {
      $body = (
        <Elevation z={4} className="DayPickerWrapper">
          <DayPicker
            className="DayPicker"
            numberOfMonths={this.props.numberOfMonths}
            selectedDays={selectedDays}
            modifiers={modifiers}
            onDayClick={this.setCalendarRange}
          />
        </Elevation>
      );
    }

    return (
      <>
        <IconButton
          icon="calendar_today"
          label="Show Calender"
          onClick={this.toggleCalendar}
        />
        {$body}
      </>
    );
  }

  setStr = (type)=> {
    return (evt)=> {
      const {value} = evt.target;
      const moment = Moment(value, DateStrFormat);

      if (value.match(DateStrRegex) && moment.isValid()) {
        this.setState({
          [`${type}Str`]: null
        }, ()=> {
          this.props.onChange({
            [type]: moment
          });
        });
      } else {
        this.setState({
          [`${type}Str`]: value
        });
      }
    };
  }

  setPreset = (evt)=> {
    const {value} = evt.target;
    const preset = Presets.find(({label})=> (label === value));
    const {start, end} = preset;
    this.props.onChange({start, end});
  }

  setCalendarRange = (day)=> {
    let {start, end} = this.props;
    let from = start.toDate();
    let to = end.toDate();
    ({from, to} = DateUtils.addDayToRange(day, {from, to}));
    start = Moment(from);
    end = Moment(to);
    this.props.onChange({start, end});
  }

  toggleCalendar = ()=> {
    let {calendarOpen} = this.state;
    calendarOpen = !calendarOpen;
    this.setState({calendarOpen});
  }
}

DatePicker.propTypes = {
  numberOfMonths: PropTypes.number,
  start: PropTypes.object,
  end: PropTypes.object,
  onChange: PropTypes.func
};

export default DatePicker;
