import React from 'react';
import PropTypes from 'prop-types';

import {Page, Enums} from '@tripp/shared';
import {getCompany} from './CompanyQueries';
import {Gifts} from '../Gift';

import './Company.scss';

class CompanyPage extends React.Component {
  render () {
    const {id} = this.props.params;

    return (
      <Page
        className="CompanyPage"
        query={getCompany}
        variables={{id}}
      >
        {({getCompany: company})=> {
          //const {name} = company;

          return (
            <Gifts
              gifter={company}
              accessType={Enums.GiftAccessType.Company}
              gifterType={Enums.GifterType.Company}
              getGifter={getCompany}
            />
          );
        }}
      </Page>
    );
  }
}

CompanyPage.propTypes = {
  router: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default CompanyPage;
