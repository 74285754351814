import React, {Component} from 'react';
import PropTypes from 'prop-types';

import GroupedBarChart from './GroupedBarChart';

class SurveyCompletion extends Component {
  render () {
    const {group, interval} = this.props;
    const byDate = (group === interval);
    const labelKey = byDate ? 'surveyName' : interval.toLowerCase();
    const valueKey = 'completionPercent';

    return (
      <div className="SurveyCompletion">
        <GroupedBarChart
          className="SurveyCompletion"
          queryName="statsSurveyCompletion"
          variables={this.props}
          valueKey={valueKey}
          labelKey={labelKey}
          label="Average Rating"
        />
      </div>
    );
  }
}

SurveyCompletion.propTypes = {
  group: PropTypes.string,
  interval: PropTypes.string
};

export default SurveyCompletion;
