import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card} from '@rmwc/card';
import {Typography} from '@rmwc/typography';
import {ListDivider} from '@rmwc/list';

import {Enums} from '@tripp/shared';
import {
  KPI,
  MoodImprovement,
  MoodMatrix,
  MoodScale,
  RatingsSurvey,
  RunCompletion,
  RunCompletionByGroup,
  RunCompletionBySubgroup,
  RunDuration,
  SessionDuration,
  SurveyCompletion
} from '.';

class AllStats extends Component {
  render () {
    const {props} = this;
    console.log('getting dem stats', props);

    return (
      <div className="AllStats">
        <StatCard title="Key Performance Indicators">
          <KPI {...props}/>
        </StatCard>
        <StatCard title="Mood Matrix">
            <MoodMatrix {...props}/>
        </StatCard>
        <StatCard title="Survey Completion">
          <SurveyCompletion
            interval={Enums.Interval.Date}
            group={Enums.Interval.Date}
            {...props}
          />
        </StatCard>
        <StatCard title="Mood Scale">
          <MoodScale
            interval={Enums.Interval.Date}
            {...props}
          />
        </StatCard>
        <StatCard title="Mood Improvement">
          <MoodImprovement
            interval={Enums.Interval.Date}
            {...props}
          />
        </StatCard>
        <StatCard title="Ratings Survey">
          <RatingsSurvey
            interval={Enums.Interval.Date}
            {...props}
          />
        </StatCard>
        <StatCard title="Run Completion">
          <RunCompletion
            interval={Enums.Interval.Date}
            group={Enums.Interval.Date}
            {...props}
          />
        </StatCard>
        <StatCard title="Run Completion Hourly">
          <RunCompletion
            interval={Enums.Interval.Hour}
            group={Enums.Interval.Hour}
            {...props}
          />
        </StatCard>
        <StatCard title="Run Duration">
          <RunDuration
            interval={Enums.Interval.Date}
            group={Enums.Interval.Date}
            {...props}
          />
        </StatCard>
        <StatCard title="Session Duration">
          <SessionDuration
            interval={Enums.Interval.Date}
            group={Enums.Interval.Date}
            {...props}
          />
        </StatCard>
        <StatCard title="Run Completion By Location">
          <RunCompletionBySubgroup
            interval={Enums.Interval.Date}
            group="company_seat_location"
            {...props}
          />
        </StatCard>
        <StatCard title="Run Completion By Experience Type">
          <RunCompletionBySubgroup
            interval={Enums.Interval.Date}
            group="experience_type"
            {...props}
          />
        </StatCard>
        <StatCard title="Run Completion By Location">
          <RunCompletionByGroup
            interval={Enums.Interval.Date}
            group="company_seat_location"
            {...props}
          />
        </StatCard>
        <StatCard title="Run Completion By Seat">
          <RunCompletionByGroup
            interval={Enums.Interval.Date}
            group="username"
            {...props}
          />
        </StatCard>
      </div>
    );
  }
}

class StatCard extends React.Component {
  render () {
    const {title} = this.props;
    return (
      <Card className="StatCard">
        <Typography
          className="Header"
          use="headline6"
          tag="div"
          style={{padding: '0.5rem 1rem'}}
          theme="text-secondary-on-background"
        >
          {title}
        </Typography>
        <ListDivider />
        <div className="Body">
          {this.props.children}
        </div>
      </Card>
    );
  }
}

StatCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object
};
export default AllStats;
