import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from './DatePicker';
import Filters from './Filters';

class StatsControls extends React.Component {
  render () {
    const {start, end, filters, company} = this.props;

    return (
      <div className="StatsControls">
        <DatePicker
          start={start}
          end={end}
          numberOfMonths={2}
          onChange={this.props.onChange}
        />
        <Filters
          company={company}
          filters={filters}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

StatsControls.propTypes = {
  start: PropTypes.object,
  end: PropTypes.object,
  filters: PropTypes.array,
  company: PropTypes.object,
  onChange: PropTypes.func
};

export default StatsControls;
