import gql from 'graphql-tag';

const statsKPI = gql`
  query statsKPI (
    $filters: [Filter],
    $companyId: FirestoreID,
    $start: Moment!,
    $end: Moment!) {
      statsKPI(
        companyId: $companyId,
        filters: $filters,
        start: $start,
        end: $end) {
          installCount,
          userCount,
          sessionDuration,
          ratingAvg,
          runStartCount,
          sessionCount,
          activeUsersCount,
          moodScaleOpeningAvg,
          moodScaleClosingAvg,
          moodShiftAvg,
          runDurationAvg,
          runEndTotal,
          moodScaleOpeningCount,
          moodScaleClosingCount,
          matrixOpeningCount,
          matrixClosingCount
      }
  }
`;


const statsMoodMatrices = gql`
  fragment moodItem on MoodItem {
    mood
    weight
    total
  }

  query statsMoodMatrices (
    $filters: [Filter],
    $companyId: FirestoreID,
    $start: Moment!,
    $end: Moment!
  ) {
    Opening: statsMoodMatrix(
      companyId: $companyId,
      filters: $filters,
      start: $start,
      end: $end,
      surveyType: VROpening
    ) {
      ...moodItem
    }
    Closing: statsMoodMatrix(
      companyId: $companyId,
      filters: $filters,
      start: $start,
      end: $end,
      surveyType: VRClosing
    ) {
      ...moodItem
    }
  }
`;

const statsMoodScale = gql`
query statsMoodScale(
  $interval: Interval!,
  $filters: [Filter],
  $companyId: FirestoreID,
  $start: Moment!,
  $end: Moment!
) {
  statsMoodScale(
    interval: $interval,
    filters: $filters,
    companyId: $companyId,
    start: $start,
    end: $end
  )
}
`;

const statsSurveyCompletion = gql`
  query statsSurveyCompletion(
    $interval: Interval!,
    $filters: [Filter],
    $group: String!
    $companyId: FirestoreID,
    $start: Moment!,
    $end: Moment!
  ) {
    statsSurveyCompletion(
      interval: $interval,
      filters: $filters,
      group: $group,
      companyId: $companyId,
      start: $start,
      end: $end
    ) {
      name
      items {
        date,
        month,
        year,
        surveyName,
        runStartCount,
        surveyCount,
        completionPercent
      }
    }
  }
`;

const statsMoodImprovement = gql`
  query statsMoodImprovement(
    $interval: String,
    $filters: [Filter],
    $companyId: FirestoreID,
    $start: Moment!,
    $end: Moment!,
  ) {
  statsMoodImprovement(
    interval: $interval,
    filters: $filters,
    companyId: $companyId,
    start: $start,
    end: $end,
  ) {
    name,
    items {
      date,
      tally,
      result
    }
  }
}
`;

const statsRatingsSurvey = gql`
  query statsRatingsSurvey(
    $interval: String,
    $filters: [Filter],
    $companyId: FirestoreID,
    $start: Moment!,
    $end: Moment!) {
      statsRatingsSurvey(
      interval: $interval,
      filters: $filters,
      companyId: $companyId,
      start: $start,
      end: $end
    )
}
`;

const statsRunCompletion = gql`
  query statsRunCompletion(
    $interval: Interval!,
    $filters: [Filter],
    $companyId: FirestoreID,
    $start: Moment!,
    $end: Moment!,
    $group: String!) {
      statsRunCompletion(
        interval: $interval,
        filters: $filters,
        companyId: $companyId,
        start: $start,
        end: $end,
        group: $group)
}
`;

const statsRunDuration = gql`
  query statsRunDuration(
    $interval: String,
    $filters: [Filter],
    $companyId: FirestoreID,
    $start: Moment!,
    $end: Moment!,
    $group: String!) {
      statsRunDuration(
        interval: $interval,
        filters: $filters,
        companyId: $companyId,
        start: $start,
        end: $end,
        group: $group
      )
  }
`;

const statsSessionDuration = gql`
  query statsSessionDuration(
    $interval: String,
    $filters: [Filter],
    $companyId: FirestoreID,
    $start: Moment!,
    $end: Moment!,
    $group: String!) {
      statsSessionDuration(
        interval: $interval,
        filters: $filters,
        companyId: $companyId,
        start: $start,
        end: $end,
        group: $group
      )
  }
`;

export {
  statsKPI,
  statsMoodImprovement,
  statsMoodMatrices,
  statsMoodScale,
  statsRatingsSurvey,
  statsRunCompletion,
  statsRunDuration,
  statsSessionDuration,
  statsSurveyCompletion
};
