import gql from 'graphql-tag';

const headsetOrderFragment = gql`
  fragment headsetOrderFragment on HeadsetOrder {
    id
    stripeOrder
    stripeSku
  }
`;

const createHeadsetOrder = gql`
  ${headsetOrderFragment}
  mutation createHeadsetOrder ($input: HeadsetOrderCreateInput!) {
    createHeadsetOrder (input: $input) {
      ...headsetOrderFragment
    }
  }
`;

const updateHeadsetOrder = gql`
  ${headsetOrderFragment}
  mutation updateHeadsetOrder (
    $id: FirestoreID!,
    $input: HeadsetOrderUpdateInput!
  ) {
    updateHeadsetOrder (id: $id, input: $input) {
      ...headsetOrderFragment
    }
  }
`;

const createBilling = gql`
  ${headsetOrderFragment}
  mutation createBilling ($input: BillingCreateInput!) {
    createBilling (input: $input) {
      id
      service
      subscription {
        status
        stripePlan
        stripeSubscription
      }
      createdAt
      updatedAt
      stripeCustomer
      headsetOrder {
        ...headsetOrderFragment
      }
    }
  }
`;

const findCompanyByAccessCode = gql`
  query findCompanyByAccessCode ($accessCode: String!) {
    findCompanyByAccessCode (accessCode: $accessCode) {
      id
      name
    }
  }
`;

const listBillingOptions = gql`
  query listBillingOptions {
    listBillingOptions {
      stripePlanId
      stripeProductId
      stripePlan
      stripeProduct
    }
  }
`;

export {
  createHeadsetOrder,
  updateHeadsetOrder,
  createBilling,
  findCompanyByAccessCode,
  listBillingOptions
};
