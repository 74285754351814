import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {AuthenticationCentered} from '@tripp/shared';

import './Authentication.scss';

class AuthenticationPage extends Component {
  render () {
    const {route, router} = this.props;
    const mode = route.name;

    return (
      <div className="Page AuthenticationPage VerticallyCenteredPage">
        <AuthenticationCentered
          router={router}
          mode={mode}
          allowSignup={true}
        />
      </div>
    );
  }

  static hideTopBar () {
    return true;
  }
}

AuthenticationPage.propTypes = {
  route: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default AuthenticationPage;
