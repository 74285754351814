import React, {Component} from 'react';

import QueryAndChart from './QueryAndChart';
import MoodColors from './MoodColors';
import './Stats.scss';

class MoodScale extends Component {
  render () {
    const variables = this.props;
    const options = {
      elements: {
        line: {
          tension: 0
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };
    return (
      <QueryAndChart
        className="MoodScale"
        queryName="statsMoodScale"
        variables={variables}
        process={this.process}
        type="line"
        options={options}
      />
    );
  }

  process = (data)=> {
    function shortLabel (label) {
      const isOpening = (label === 'moodScaleOpeningAvg');
      return isOpening ? 'Opening' : 'Closing';
    }

    const labels = [];
    const datas = {
      moodScaleOpeningAvg: [],
      moodScaleClosingAvg: []
    };
    const keys = Object.keys(datas);
    for (const {name, items} of data.statsMoodScale) {
      labels.push(name);
      for (const item of items) {
        for (const key of keys) {
          const value = item[key];
          datas[key].push(value);
        }
      }
    }

    const datasets = Object.entries(datas).map(([label, data])=> {
      label = shortLabel(label);
      const colors = MoodColors[label];
      label = `${label} Mood Average`;

      return {
        label,
        data,
        ...colors,
        fill: false
      };
    });

    return {datasets, labels};
  };
}

export default MoodScale;
