import React from 'react';
import {Elevation} from '@rmwc/elevation';
import {Typography} from '@rmwc/typography';

import {Page, session} from '@tripp/shared';
import EditPersonaForm from './EditPersonaForm';
import {getPersona} from './ProfileQueries';

import './Profile.scss';

class ProfilePage extends React.Component {
  render () {
    const id = session.personaId();
    return (
      <Page
        className="ProfilePage CenteredPage"
        query={getPersona}
        variables={{id}}
      >
        {({getPersona: persona})=> {
          return (
            <Elevation z={3} className="Centered">
              <Typography
                className="FormTitle"
                use="headline5"
              >
                Edit your Profile
              </Typography>
              <EditPersonaForm persona={persona}/>
            </Elevation>
          );
        }}
      </Page>
    );
  }
}

export default ProfilePage;
