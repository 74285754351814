import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Color} from '@tripp/shared';
import QueryAndChart from './QueryAndChart';
import './Stats.scss';

class BarChart extends Component {
  render () {
    const {options} = this.props;
    const type = this.props.type ? this.props.type : 'bar';
    const process = this.props.process ? this.props.process(this.props) : this.process;
    return (
      <QueryAndChart
        {...this.props}
        process={process}
        type={type}
        options={options}
      />
    );
  }

  process = (data)=> {
    const {label, labelKey, valueKey, queryName} = this.props;

    if (queryName in data) {
      data = data[queryName];
    }

    const {backgroundColor, borderColor} = Color.nice();
    const borderWidth = 1;
    const labels = [];
    const datums = [];
    for (const {items} of data) {
      for (const item of items) {
        labels.push(item[labelKey]);
        datums.push(item[valueKey]);
      }
    }

    data = {
      labels,
      datasets: [{
        label: label || valueKey,
        data: datums,
        backgroundColor,
        borderWidth,
        borderColor
      }]
    };

    return data;
  };
}

BarChart.propTypes = {
  group: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  queryName: PropTypes.string,
  options: PropTypes.object,
  type: PropTypes.string,
  process: PropTypes.object,
  label: PropTypes.string
};

export default BarChart;
