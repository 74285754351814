import React from 'react';
import {Select} from '@rmwc/select';
import {Button} from '@rmwc/button';

class ShippingMethods extends React.Component {
  constructor (props) {
    super(props);
    const {selected_shipping_method: selected} = props.order;
    this.state = {
      open: false,
      selected
    };
  }

  render () {
    const {open} = this.state;
    const $body = open ? this.renderOpen() : this.renderClosed();
    return (
      <div className="ShippingMethods">
        {$body}
      </div>
    );
  }

  renderClosed () {
    const {order} = this.props;
    const selected = this.getSelected();
    const {description, date} = selected;
    return (
      <>
        <div className="SelectedShipping">
          Should arrive on <strong>{date}</strong> via {description}
        </div>
        <Button onClick={this.toggle}>
          Edit Shipping Method
        </Button>
      </>
    );
  }

  renderOpen () {
    const {selected} = this.state;
    const {order} = this.props;
    const {shipping_methods: methods} = order;

    // label/value
    const options = methods.map((method)=> {
      method = this.unpackMethod(method);
      const {id: value, price, description, date} = method;
      const label = `${date} ${price} (${description})`;
      return {label, value};
    });

    return (
      <>
        <Select
          label="Shipping Methods"
          value={selected}
          options={options}
          onChange={(event)=> {
            event.preventDefault();
            const {value: selected} = event.target;
            this.setState({selected});
          }}
          placeholder=""
        />
        <Button
          raised
          onClick={this.update}
        >
          Update Order
        </Button>
      </>
    );
  }

  toggle = (event)=> {
    event.preventDefault();
    let {open} = this.state;
    open = !open;
    this.setState({open});
  };

  update = (event)=> {
    event.preventDefault();
    const {onChange} = this.props;
    const method = this.getSelected();
    onChange(method);
  };

  getSelected () {
    const {selected} = this.state;
    const {order} = this.props;
    const {shipping_methods: methods} = order;
    const method = methods.find((method)=> method.id === selected);
    return this.unpackMethod(method);
  }

  unpackMethod (method) {
    const {
      id,
      amount,
      description,
      delivery_estimate: {date}
    } = method;
    const price = `$${amount / 100}`;
    return {id, price, description, date};
  }
}

export default ShippingMethods;
