import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import StackedGroupedBarChart from './StackedGroupedBarChart';
import './Stats.scss';

class RunCompletionBySubgroup extends Component {
  render () {
    const {interval, group, options} = this.props;
    const xAxislabelKey = interval.toLowerCase();
    const groupKey = _.camelCase(group);
    const subgroupKey = 'disposition';
    const valueKey = 'tally';

    return (
      <div className="RunCompeletionBySubGroup">
        <StackedGroupedBarChart
          className="RunCompeletionBySubGroup"
          queryName="statsRunCompletion"
          xAxisLabelKey={xAxislabelKey}
          groupKey={groupKey}
          subgroupKey={subgroupKey}
          valueKey={valueKey}
          variables={this.props}
          options={options}
        />
      </div>
    );
  }
}

RunCompletionBySubgroup.propTypes = {
  group: PropTypes.string,
  interval: PropTypes.string,
  options: PropTypes.object
};

export default RunCompletionBySubgroup;
