import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  Enums,
  session
} from '@tripp/shared';
import {getPersonaWithGifts} from './ProfileQueries';
import {Gifts} from '../Gift';

import './Profile.scss';

class ProfileGiftsPage extends React.Component {
  render () {
    const id = session.personaId();

    return (
      <Page
        className="ProfileGiftsPage"
        query={getPersonaWithGifts}
        variables={{id}}
      >
        {({getPersona: persona})=> {
          const {username} = persona;

          return (
            <div className="PersonaGifts">
              <h1>{username} - Gifts</h1>
              <Gifts
                gifter={persona}
                accessType={Enums.GiftAccessType.Public}
                gifterType={Enums.GifterType.Persona}
                getGifter={getPersonaWithGifts}
              />
            </div>
          );
        }}
      </Page>
    );
  }
}

ProfileGiftsPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default ProfileGiftsPage;
