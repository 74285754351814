import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {Color} from '@tripp/shared';
import BarChart from './BarChart';
import './Stats.scss';

class RunCompletionByGroup extends Component {
  render () {
    const {group} = this.props;
    const labelKey = group;
    const valueKey = 'tally';
    const options = {
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    return (
      <div className="RunCompeletion">
        <BarChart
          className="RunCompeletion"
          queryName="statsRunCompletion"
          variables={this.props}
          valueKey={valueKey}
          labelKey={labelKey}
          options={options}
          process={this.process}
          type='horizontalBar'
          label='Runs Completed'
        />
      </div>
    );
  }

  process = (props)=> (data)=> {
    const {label, valueKey, queryName} = props;
    const labelKey = _.camelCase(props.labelKey);

    if (queryName in data) {
      data = data[queryName];
    }

    const {backgroundColor, borderColor} = Color.nice();
    const borderWidth = 1;


    const columns = {};

    for (const {items} of data) {
      for (const item of items) {
        if (item[labelKey] && (item.disposition !== 'Abort')) {
          // NOTE: This is temporary and should be removed in 1+ month from 1/09/19
          const labelMap = {
            CokeSeat15: 'BULGARIA3',
            CokeSeat14: 'BULGARIA2',
            CokeSeat13: 'BULGARIA1'
          };

          let label = item[labelKey];
          if (label in labelMap) {
            label = labelMap[label];
          }

          if (columns[label]) {
            columns[label] += item.tally;
          } else {
            columns[label] = item.tally;
          }
        }
      }
    }

    const labels = Object.keys(columns);
    const datums = Object.values(columns);

    data = {
      labels,
      datasets: [{
        label: label || valueKey,
        data: datums,
        backgroundColor,
        borderWidth,
        borderColor
      }]
    };

    return data;
  };
}

RunCompletionByGroup.propTypes = {
  group: PropTypes.string,
  interval: PropTypes.string,
  options: PropTypes.object
};

export default RunCompletionByGroup;
