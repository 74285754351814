import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {withApollo} from 'react-apollo';
import {Typography} from '@rmwc/typography';
import {TextField} from '@rmwc/textfield';

import {
  ImageUploader,
  session,
  AsyncButton
} from '@tripp/shared';
import {
  getPersona,
  deletePersonaAvatar,
  updatePersona
} from './ProfileQueries';

import './Profile.scss';

const PERSONA_TEXT_FIELDS = ['username', 'displayName', 'mantra'];

class EditPersonaForm extends React.Component {
  state = {
    avatar: null,
    username: null,
    displayName: null,
    mantra: null
  }

  constructor (props) {
    super(props);
    const {persona} = props;
    const state = {
      avatar: persona.avatar
    };
    for (const attr of PERSONA_TEXT_FIELDS) {
      let val = persona[attr];
      if (!val && val !== '') {
        val = '';
      }
      state[attr] = val;
    }
    this.state = state;
  }

  // given how we're creating state from props, we may need to add
  // getDerivedStateFromProps, but its currently working and i'm lazy
  // https://medium.com/@ddunderfelt/controlled-forms-with-react-f7ecc1ce6155

  render () {
    return (
      <div className="Persona">
        {this.renderAvatar(this.props.persona.avatar)}
        {this.renderField('username')}
        {this.renderField('displayName')}
        {this.renderField('mantra')}
        <AsyncButton
          action={this.updatePersona}
        >
          Update
        </AsyncButton>
      </div>
    );
  }

  renderAvatar (avatar) {
    let $body;
    if (avatar) {
      const {url} = avatar.sizes[0];
      $body = (
        <div className="PersonaAvatar">
          <img
            src={url}
            alt="Profile avatar"
          />
          <AsyncButton
            action={this.deletePersonaAvatar}
          >
            Delete avatar
          </AsyncButton>
        </div>
      );
    } else {
      $body = (
        <ImageUploader
          multi={false}
          showUploadButton={false}
          ref={(uploader)=> {
            if (uploader) {
              this.uploader = uploader.wrappedInstance;
            }
          }}
        />
      );
    }

    return (
      <div className="PersonaAvatar">
        <Typography
          className="FormTitle"
          use="subtitle1"
        >
          Avatar
        </Typography>
        {$body}
      </div>
    );
  }

  renderField (name) {
    const fieldClass = _.capitalize(name);
    const label = _.startCase(name);
    return (
      <div className={`FormField PersonaField Persona${fieldClass}`}>
        <TextField
          label={label}
          value={this.state[name]}
          onChange={this.onChange(name)}
        />
      </div>
    );
  }

  onChange = (name)=> {
    return (event)=> {
      event.preventDefault();
      this.setState({
        [name]: event.target.value
      });
    };
  }

  deletePersonaAvatar = ()=> {
    return this.runMutation({
      mutation: deletePersonaAvatar,
      variables: {
        id: session.personaId()
      }
    });
  }

  updatePersona = ()=> {
    const input = _.pick(this.state, PERSONA_TEXT_FIELDS);
    const {avatar} = this.state;
    return new Promise(async (resolve, reject)=> {
      try {
        if (!avatar && this.uploader.hasFiles()) {
          input.avatar = await this.uploader.upload();
        }
        const id = session.personaId();
        await this.runMutation({
          mutation: updatePersona,
          variables: {id, input}
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  runMutation ({mutation, variables}) {
    const {client} = this.props;
    const id = session.personaId();
    return client.mutate({
      mutation,
      variables,
      refetchQueries: [
        {query: getPersona, variables: {id}}
      ]
    });
    // TODO: show some indicator/snack
  }
}

EditPersonaForm.propTypes = {
  client: PropTypes.object.isRequired,
  persona: PropTypes.object.isRequired
};

export default withApollo(EditPersonaForm);
