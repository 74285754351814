import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {TextField} from '@rmwc/textfield';
import {Select} from '@rmwc/select';
import {Button} from '@rmwc/button';
import {Radio} from '@rmwc/radio';
import {Typography} from '@rmwc/typography';
import {Elevation} from '@rmwc/elevation';

import {
  session,
  ImageUploader,
} from '@tripp/shared';

import './Account.scss';

class AddPersonaPage extends React.Component {
  constructor (props) {
    super(props);

    this.uploader = null;

    this.state = {
      displayName: '',
      username: '',
      mantra: '',
      gender: '',
      submitted: false
    };
  }

  static hideTopBar () {
    const personas = _.get(session, 'account.personas', []);
    return (personas.length === 0);
  }

  render () {
    const {
      username,
      displayName,
      mantra,
      submitted
    } = this.state;

    const $gender = this.renderGenderSelect();

    return (
      <div className="AddPersonaPage CenteredPage">
        <Elevation z={3} className="Centered">
          <Typography
            className="FormTitle"
            use="headline5"
          >
            Create Persona
          </Typography>

          <div className="AvatarUploader">
            <Typography
              className="FormTitle"
              use="subtitle1"
            >
              Avatar
            </Typography>

            <ImageUploader
              multi={false}
              showUploadButton={false}
              ref={(uploader)=> {
                if (uploader) {
                  this.uploader = uploader.wrappedInstance;
                }
              }}
            />
          </div>

          <div className="FormField">
            <TextField
              label="Username"
              value={username}
              onChange={this.onChange('username')}
            />
          </div>

          <div className="FormField">
            <TextField
              label="Name"
              value={displayName}
              onChange={this.onChange('displayName')}
            />
          </div>

          <div className="FormField">
            <TextField
              label="Mantra"
              value={mantra}
              onChange={this.onChange('mantra')}
            />
          </div>

          {$gender}

          <Button
            disabled={submitted}
            unelevated
            onClick={this.addPersona}
          >
            Finish
          </Button>
        </Elevation>
      </div>
    );
  }

  renderGenderSelect () {
    const {gender} = this.state;

    const options = [
      {
        label: 'Male',
        value: 'Male'
      },
      {
        label: 'Female',
        value: 'Female'
      },
      {
        label: 'Decline to state',
        value: 'NoAnswer'
      }
    ];

    const $options = options.map(({value, label})=> (
      <Radio
        key={value}
        value={value}
        checked={(gender === value)}
        onChange={this.onChange('gender')}
      >
        {label}
      </Radio>
    ));

    return (
      <div className="GenderSelect">
        <Typography
          className="FormTitle"
          use="subtitle1"
        >
          Gender
        </Typography>
        <div>
          {$options}
        </div>
      </div>
    );
  }

  onChange = (name)=> {
    return (event)=> {
      if (event.preventDefault) {
        event.preventDefault();
      }
      this.setState({
        [name]: event.target.value
      });
    };
  }

  addPersona = async (event)=> {
    event.preventDefault();

    const {router} = this.props;

    const {uid: id} = session.user;

    const {
      displayName,
      username,
      mantra,
      gender
    } = this.state;

    const input = {
      displayName,
      username,
      mantra,
      gender
    };

    if (this.uploader.hasFiles()) {
      input.avatar = await this.uploader.upload();
    }

    this.setState({submitted: true});
    try {
      await session.addPersona({id, input});
      router.go('/');
    } catch (error) {
      this.setState({submitted: false});
      console.log('error adding persona');
      console.error(error);
    }
  };
}

AddPersonaPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default AddPersonaPage;
