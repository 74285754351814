import PropTypes from 'prop-types';

import {Color} from '@tripp/shared';
import BarChart from './BarChart';
import './Stats.scss';

class GroupedBarChart extends BarChart {
  process = (data)=> {
    const {labelKey, valueKey, queryName} = this.props;

    if (queryName in data) {
      data = data[queryName];
    }

    const labels = [];
    const datasetLabels = [];
    for (const {name, items} of data) {
      labels.push(name);
      for (const item of items) {
        const label = item[labelKey];
        if (!datasetLabels.includes(label)) {
          datasetLabels.push(label);
        }
      }
    }
    datasetLabels.sort();

    const datas = {};
    for (const {items} of data) {
      for (const label of datasetLabels) {
        const item = items.find((el)=> (el[labelKey] === label));
        const value = item ? item[valueKey] : 0;
        if (label in datas) {
          datas[label].push(value);
        } else {
          datas[label] = [value];
        }
      }
    }

    const datasets = Object.entries(datas).map(([label, data])=> {
      const {backgroundColor, borderColor} = Color.nice();
      return {label, data, backgroundColor, borderColor, borderWidth: 1};
    });

    return {datasets, labels};
  };
}

GroupedBarChart.propTypes = {
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  queryName: PropTypes.string
};

export default GroupedBarChart;
