import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@rmwc/textfield';
import {Select} from '@rmwc/select';
import {Button} from '@rmwc/button';
import {Typography} from '@rmwc/typography';
import {Elevation} from '@rmwc/elevation';
import {Switch} from '@rmwc/switch';

import {
  session,
  DateChooser,
  CountryRegionChooser
} from '@tripp/shared';

import './Account.scss';

class CreateAccountPage extends React.Component {
  constructor () {
    super();

    this.dateChooser = null;

    this.state = {
      country: '',
      region: '',
      newsletterOptIn: false,
      submitted: false
    };
  }

  static hideTopBar () {
    return true;
  }

  render () {
    const {
      country,
      region,
      newsletterOptIn,
      submitted
    } = this.state;

    return (
      <div className="CreateAccountPage CenteredPage">
        <Elevation z={3} className="Centered">
          <Typography
            className="FormTitle"
            use="headline5"
          >
            Create Your Account
          </Typography>
          <div className="DobSelect">
            <Typography
              className="FormTitle"
              use="subtitle1"
            >
              Birthday
            </Typography>
            <DateChooser
              ref={(dateChooser)=> {
                this.dateChooser = dateChooser;
              }}
            />
          </div>

          <CountryRegionChooser
            country={country}
            region={region}
            onChangeCountry={this.onChange('country')}
            onChangeRegion={this.onChange('region')}
          />

          <div className="FormField NewsletterOptIn">
            <Switch
              checked={!!newsletterOptIn}
              onChange={()=> {
                this.setState({
                  newsletterOptIn: !newsletterOptIn
                });
              }}
            >
              Receive Newsletter
            </Switch>
          </div>

          <Button
            disabled={submitted}
            unelevated
            onClick={this.createAccount}
          >
            Next
          </Button>
        </Elevation>
      </div>
    );
  }

  onChange = (name)=> {
    return (event)=> {
      if (event.preventDefault) {
        event.preventDefault();
      }
      this.setState({
        [name]: event.target.value
      });
    };
  }

  createAccount = async (event)=> {
    event.preventDefault();

    const {router} = this.props;

    const {
      country,
      region,
      newsletterOptIn
    } = this.state;

    const {uid} = session.user;

    const dob = this.dateChooser.moment();

    const input = {
      uid,
      country,
      region,
      dob,
      newsletterOptIn
    };

    this.setState({submitted: true});
    try {
      await session.createAccount(input);
      router.go('/account/persona');
    } catch (error) {
      this.setState({submitted: false});
      console.log('error creating account');
      console.error(error);
    }
  }
}

CreateAccountPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default CreateAccountPage;
