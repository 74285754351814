import React, {Component} from 'react';
import {Elevation} from '@rmwc/elevation';
import {Typography} from '@rmwc/typography';
import {Button} from '@rmwc/button';

import {session} from '@tripp/shared';

import './Authentication.scss';

class VerifyEmailPage extends Component {
  render () {
    const {email} = session.user;

    return (
      <div className="VerifyEmailPage">
        <Elevation z={3} className="Centered">
          <img alt="logo" src="/LogoSmall.png"/>
          <Typography className="Prompt" use="subtitle2">
            Please check your email {email} for a verification link.
          </Typography>
          <Button dense onClick={this.resendVerificationEmail}>
            resend verification email
          </Button>
        </Elevation>
      </div>
    );
  }

  static hideTopBar () {
    return true;
  }

  async resendVerificationEmail (event) {
    event.preventDefault();

    const {user} = session;
    try {
      await user.sendEmailVerification();
    } catch (error) {
      // TODO: replace alert with proper messaging
      console.error(error);
      window.alert('Error sending email');
    }
  }
}

export default VerifyEmailPage;
