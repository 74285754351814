import React, {Component} from 'react';
import PropTypes from 'prop-types';

import GroupedBarChart from './GroupedBarChart';
import './Stats.scss';

class RunCompletion extends Component {
  render () {
    const {group, interval, options} = this.props;
    const byDate = (group === interval);
    const labelKey = byDate ? 'disposition' : interval.toLowerCase();
    const valueKey = 'tally';

    return (
      <div className="RunCompeletion">
        <GroupedBarChart
          className="RunCompeletion"
          queryName="statsRunCompletion"
          variables={this.props}
          valueKey={valueKey}
          labelKey={labelKey}
          options={options}
        />
      </div>
    );
  }
}

RunCompletion.propTypes = {
  group: PropTypes.string,
  interval: PropTypes.string,
  options: PropTypes.object
};

export default RunCompletion;
