import gql from 'graphql-tag';

const findUpcomingExperiences = gql`
query findUpcomingExperiences($type: ExperienceType!, $days: Int!) {
  findUpcomingExperiences(type: $type, days: $days) {
    id
    name
    displayName
    availableOn
    displayDescription
    type
    teaching {
      id
      name
    }
    image {
      sizes {
        url
        height
        width
        type
      }
    }
  }
}
`;

export {
  findUpcomingExperiences
};
