import React from 'react';
//import PropTypes from 'prop-types';
import _ from 'lodash';
import {Typography} from '@rmwc/typography';
import {List, ListItem, ListDivider} from '@rmwc/list';

import {QueryAndRender} from '@tripp/shared';
import {statsKPI} from './StatsQueries';

class KPI extends React.Component {
  render () {
    return (
      <QueryAndRender
        className="KPI"
        query={statsKPI}
        variables={this.props}
        process={this.process}
        renderLoaded={this.renderLoaded}
      />
    );
  }

  process (data) {
    return Object.entries(data.statsKPI).filter(([key])=> {
      return !(key === '__typename');
    });
  }

  renderLoaded (rawData) {
    const data = rawData.reduce((acc, [key, val])=> {
      acc[key] = _.round(val, 2);
      return acc;
    }, {});
    const runCompletionRate = _.round(((data.runStartCount / data.runEndTotal) * 100), 2);

    return (
      <div>
        <Typography
          use="subtitle1"
          tag="div"
          style={{padding: '0.5rem 1rem'}}
          theme="text-secondary-on-background"
        >
          Averages
        </Typography>
        <ListDivider />
        <List>
          <ListItem>Opening Mood: {data.moodScaleOpeningAvg}</ListItem>
          <ListItem>Closing Mood: {data.moodScaleClosingAvg}</ListItem>
          <ListItem>Mood Shift: {data.moodShiftAvg}</ListItem>
          <ListItem>Rating: {data.ratingAvg}</ListItem>
          <ListItem>Session Length: {data.sessionDuration}</ListItem>
          <ListItem>Run Completion: {runCompletionRate}</ListItem>
        </List>
        <Typography
          use="subtitle1"
          tag="div"
          style={{padding: '0.5rem 1rem'}}
          theme="text-secondary-on-background"
        >
          Tallys
        </Typography>
        <ListDivider />
        <List>
          <ListItem>Opening Mood: {data.moodScaleOpeningCount}</ListItem>
          <ListItem>Closing Mood: {data.moodScaleClosingCount}</ListItem>
          <ListItem>Opening Mood: {data.matrixOpeningCount}</ListItem>
          <ListItem>Closing Mood: {data.matrixClosingCount}</ListItem>
          <ListItem>Sessions: {data.sessionCount}</ListItem>
        </List>
      </div>
    );
  }
}

export default KPI;
