import React from 'react';
import _ from 'lodash';
import {Button} from '@rmwc/button';
import {Typography} from '@rmwc/typography';

import {session} from '@tripp/shared';

import './Billing.scss';

class BillingPage extends React.Component {
  render () {
    const billing = this.getBilling();

    let mode;
    if (!billing) {
      mode = 'NoBilling';
    } else if (billing.active) {
      mode = 'ActiveBilling';
    } else {
      mode = 'InactiveBilling';
    }

    return (
      <div className="BillingPage">
        {this[`render${mode}`]()}
      </div>
    )
  }

  getBilling () {
    return _.get(session, 'account.billing');
  }

  renderNoBilling () {
    return (
      <>
        <Typography use="headline6">
          No subscription.
        </Typography>
        <Button
          className="GotoCreateBilling"
          raised
          onClick={this.gotoCreateBilling}
        >
          Subscribe
        </Button>
      </>
    );
  }

  renderActiveBilling () {
    const billing = this.getBilling();
    return (
      <>
        <Typography use="headline6">
          Active subscription
        </Typography>
        <pre>
          {JSON.stringify(billing, null, 1)}
        </pre>
      </>
    );

  }

  renderInactiveBilling () {
    return (
      <Typography use="headline6">
        Inactive subscription
      </Typography>
    );
  }

  gotoCreateBilling = ()=> {
    const {router} = this.props;
    router.go('/billing/create');
  };
}

export default BillingPage;
