import React from 'react';

class BillingOption extends React.Component {
  render () {
    const {onSelect, option} = this.props;

    const classNames = ['BillingOption'];
    if (onSelect) {
      classNames.push('Selectable');
    }

    const {name, pricePlan, priceHeadset, image} = this.format(option);

    let $image = ''; // TODO: placeholder image?
    if (image) {
      $image = <img src={image}/>;
    }

    let $priceHeadset = '';
    if (priceHeadset) {
      $priceHeadset = <div>Headset: {priceHeadset}</div>
    }

    return (
      <div
        className={classNames.join(' ')}
        onClick={(evt)=> {
          if (onSelect) {
            evt.preventDefault();
            onSelect(option);
          }
        }}
      >
        {$image}
        <div>{name}</div>
        <div>Subscription: {pricePlan}</div>
        {$priceHeadset}
      </div>
    );
  }

  format (option) {
    const {
      stripePlan: plan,
      stripeProduct: headset
    } = option;

    const {nickname: planName, amount, interval, product} = plan;
    const {name: productName} = product;
    const pricePlan = `$${amount / 100}/${interval}`;
    let name = `${productName} ${planName}`;

    let priceHeadset = 0;
    let image = null;

    if (headset) {
      const {name: headsetName} = headset;
      const sku = headset.skus[0];
      image = headset.images[0];
      name = `${name} bundle with ${headsetName}`;
      priceHeadset = `$${sku.price / 100}`;
    }

    return {name, pricePlan, priceHeadset, image};
  }
}

export default BillingOption;
