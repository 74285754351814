import gql from 'graphql-tag';

const giftFragment = `
  id
  media {
    uuid
    ... on Image {
      sizes {
        type
        width
        height
        url
      }
    }
  }
`;

const createGifts = gql`
  mutation createGifts ($input: GiftsCreateInput!) {
    createGifts(input: $input) {
      ${giftFragment}
    }
  }
`;

const deleteGifts = gql`
  mutation deleteGifts ($ids: [FirestoreID!]!) {
    deleteGifts(ids: $ids) {
      deleted
      deletedAt
    }
  }
`;

export {
  giftFragment,
  createGifts,
  deleteGifts
};
