// stub session class
// TODO: implement firebase auth etc.
import _ from 'lodash';
import gql from 'graphql-tag';
import uuidv4 from 'uuid/v4';

import firebase from '../firebase';
import getGraphQLErrorCode from '../getGraphQLErrorCode';

const auth = firebase.auth();

const AUTH_REDIRECT_PATH_KEY = 'AuthRedirectPath';
const AUTH_REDIRECT_STATE_KEY = 'AuthRedirectState';


const personaFragment = `
  id
  username
  displayName
  avatar {
    sizes {
      url
      width
      height
    }
  }
`
const accountFragment = `
  id
  type
  isAdmin
  role
  email
  personas {
    ${personaFragment}
  }
  company {
    id
    name
    sessionWorker {
      id
      type
      isAdmin
      adminType
      seatGroup
      seatLocation
    }
  }
`;

// TODO: update to changed billing schema
// billing {
//   id
//   plan {
//     stripePlan {
//       stripePlanId
//       name
//       interval
//       priceDollars
//     }
//     stripeSubscription {
//       id
//       billing
//       billing_cycle_anchor
//       created
//       current_period_end
//       current_period_start
//       start
//       status
//     }
//   status
// }

export default class Session {
  constructor () {
    this.user = null;
    this.account = null;
    this.token = null;
    this.client = null;
    this.removeListener = null;
    this.deviceId = this.getDeviceId();
  }

  hasRole (role) {
    const accountRole = _.get(this, 'account.role', null);
    return (role === accountRole);
  }

  signedIn () {
    return !!this.token;
  }

  setClient (client) {
    this.client = client;
  }

  async authenticate ({email, password, provider, mode}) {
    let result;
    if (provider) {
      const AuthProvider = firebase.auth[`${provider}AuthProvider`];
      provider = new AuthProvider();
      result = await auth.signInWithRedirect(provider);
    } else {
      let action;
      if (mode === 'SignIn') {
        action = 'signInWithEmailAndPassword';
      } else if (mode === 'SignUp') {
        action = 'createUserWithEmailAndPassword';
      } else {
        throw new Error(`Invalid authentication mode: ${mode}`);
      }
      result = await auth[action](email, password);
      if (action === 'createUserWithEmailAndPassword') {
        result = await auth.currentUser.sendEmailVerification();
      }
    }

    return result;
  }

  signOut () {
    return auth.signOut();
  }

  async listen (onChange) {
    // TODO: This can probably be removed, because onAuthStateChanged
    //       will accomplish what we want
    //
    // try {
    //   const result = await auth.getRedirectResult();
    //   if (result.user) {
    //     this.user = result.user;
    //   }
    // } catch (error) {
    //   console.error('redirect error', error);
    // }
    this.removeListener = auth.onAuthStateChanged(async (user)=> {
      this.user = user;
      if (user) {
        this.token = await user.getIdToken(true);
        this.account = await this.loadAccount();
      } else {
        this.token = null;
        this.account = null;
      }
      if (onChange) {
        onChange(user);
      }
    });
  }

  getDeviceId () {
    const {localStorage} = window;
    const key = 'TRIPP_DEVICE_ID';
    let deviceId = localStorage.getItem(key);
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem(key, deviceId);
    }
    return deviceId;
  }

  stopListening () {
    if (this.removeListener) {
      this.removeListener();
    }
  }

  setAuthRedirect (redirect) {
    const {path} = redirect;
    localStorage.setItem(AUTH_REDIRECT_PATH_KEY, path);
    if (redirect.state) {
      const state = JSON.stringify(redirect.state);
      localStorage.setItem(AUTH_REDIRECT_STATE_KEY, state);
    }
  }

  getAuthRedirectPath () {
    const path = localStorage.getItem(AUTH_REDIRECT_PATH_KEY);
    if (path) {
      localStorage.removeItem(AUTH_REDIRECT_PATH_KEY);
      return path;
    } else {
      return null;
    }
  }

  getAuthRedirectState () {
    try {
      const state = localStorage.getItem(AUTH_REDIRECT_STATE_KEY);
      if (state) {
        localStorage.removeItem(AUTH_REDIRECT_STATE_KEY);
        return JSON.parse(state);
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error reading redirect');
      return null;
    }
  }

  async loadAccount () {
    try {
      const response = await this.client.query({
        query: gql`
          {
            getSessionAccount {
              ${accountFragment}
            }
          }
        `
      });
      return _.get(response, 'data.getSessionAccount', null);
    } catch (error) {
      // TODO: React error boundaries...
      const code = getGraphQLErrorCode(error);
      if (code) {
        // TODO: handle expired token, other auth error codes
        // AuthAccountMissingError
        // AuthTokenError
        // AuthTokenExpiredError
        console.error(`Session error: ${code}`);
      } else {
        console.error(error);
      }
      if (code === 'AuthToken') {
        this.signOut();
        return null;
      } else {
        throw new Error('Error loading account');
      }
    }
  }

  async createAccount (input) {
    try {
      const mutation = gql`
        mutation createAccount($input: AccountCreateInput!) {
          createAccount(input: $input) {
            ${accountFragment}
          }
        }
      `;
      const variables = {input};
      const response = await this.client.mutate({mutation, variables});
      this.account = _.get(response, 'data.createAccount', null);
    } catch (error) {
      const msg = 'Error creating account';
      console.error(msg, error);
      throw new Error(msg);
    }
  }

  async updateAccount (input) {
    try {
      const mutation = gql`
        mutation updateAccount($id: FirestoreID!, $input: AccountUpdateInput!) {
          updateAccount(id: $id, input: $input) {
            ${accountFragment}
          }
        }
      `;
      const {id} = this.account;
      const variables = {id, input};
      const response = await this.client.mutate({mutation, variables});
      this.account = _.get(response, 'data.updateAccount', null);
    } catch (error) {
      const msg = 'Error updating account';
      console.error(msg, error);
      throw new Error(msg);
    }
  }

  async addPersona ({id, input}) {
    try {
      const mutation = gql`
        mutation addPersona($id: FirestoreID!, $input: PersonaAddInput!) {
          addPersona(id: $id, input: $input) {
            ${personaFragment}
          }
        }
      `;
      const variables = {id, input};
      const response = await this.client.mutate({mutation, variables});
      const persona = _.get(response, 'data.addPersona');
      this.account.personas.push(persona);
      return persona;
    } catch (error) {
      const msg = 'Error creating account';
      console.error(msg, error);
      throw new Error(msg);
    }
  }

  personaId () {
    // TODO: have session store persona index or id..
    return _.get(this, 'account.personas[0].id', null);
  }

  isCompanyAdmin () {
    return _.get(this.account, 'company.sessionWorker.isAdmin', false);
  }
}
