import React from 'react';
import PropTypes from 'prop-types';

import {
  Application,
  session
} from '@tripp/shared';

import router from './router';
import './UserApplication.scss';

class UserApplication extends React.Component {
  render () {
    const {pathname} = this.props;

    return (
      <Application
        menu={this.menu}
        title="TRIPP"
        router={router}
        pathname={pathname}
      />
    );
  }

  componentDidMount () {
    const {Intercom} = window;
    Intercom('boot', {
      app_id: 'zgjltf0j'
    });

    router.onChange(()=> {
      Intercom('update');
    });
  }

  menu () {
    const menu = [
      {
        label: 'Profile',
        url: '/profile'
      },
      {
        label: 'Gifts',
        url: '/profile/gifts'
      }
    ];

    if (session.isCompanyAdmin()) {
      const {company} = session.account;
      const baseUrl = `/company/${company.id}`;
      menu.unshift({
        label: company.name,
        submenu: [
          {
            icon: 'dashboard',
            label: 'Dashboard',
            url: baseUrl
          },
          {
            icon: 'insert_photo',
            label: 'Gifts',
            url: `${baseUrl}/gifts`
          }
        ]
      });
    }

    return menu;
  }
}

UserApplication.propTypes = {
  pathname: PropTypes.string.isRequired
};

export default UserApplication;
