import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardMedia, CardMediaContent} from '@rmwc/card';
import {Typography} from '@rmwc/typography';
import Moment from 'moment';

import {QueryAndRender, Enums} from '@tripp/shared';
import {findUpcomingExperiences} from './HomeQueries';

class UpcomingExperiences extends React.Component {
  render () {
    return (
      <QueryAndRender
        className="UpcomingExperiences"
        query={findUpcomingExperiences}
        variables={this.props}
        process={this.process}
        renderLoaded={this.renderLoaded}
      />
    );
  }

  process (data) {
    return data.findUpcomingExperiences;
  }

  renderLoaded = (experiences)=> {
    const $experiences = experiences.map((props, i)=> {
      return (
        <ExperienceCard
          {...props}
          i={i}
          key={i}
        />
      );
    });

    return (
      <div>
        <Typography className="Title" use="headline6" tab="h2">
          Upcoming Experiences
        </Typography>
        {$experiences}
      </div>
    );
  }
}

class ExperienceCard extends React.Component {
  render () {
    const {
      displayName,
      image,
      id,
      i
    } = this.props;

    const {ImageSizeType} = Enums;

    let url = null;
    const width = 240;
    let height = 240;
    // TODO: do a better job of defaulting/fallback logic
    if (image && image.sizes && image.sizes.length) {
      let size = image.sizes.find((size)=> {
        return size.type === ImageSizeType.SquareMedium;
      });
      if (!size) {
        size = image.sizes[0];
      }
      const {height: aheight, width: awidth} = size;
      ({url} = size);
      height = width * (aheight / awidth);
    }

    const cardStyle = {width, height};
    if (url) {
      cardStyle.backgroundImage = `url(${url})`;
    } else {
      cardStyle.backgroundColor = 'rgb(175, 166, 204)';
    }

    return (
      <Card className="ExperienceCard" key={id}>
        <CardMedia
          style={cardStyle}
        >
          <CardMediaContent>
            <Typography
              use="headline5"
              tag="div"
              theme="text-primary-on-dark"
              style={{
                padding: '0.5rem 1rem',
                backgroundImage:
                  'linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.5) 100%)',
                bottom: '0',
                left: '0',
                right: '0',
                position: 'absolute'
              }}
            >
              {displayName}
            </Typography>
          </CardMediaContent>
        </CardMedia>
        <div style={{padding: '0 1rem 0 1rem'}}>
          <Typography
              use="headline6"
              tag="h2"
              theme="text-secondary-on-background"
          >
            {Moment().add(i, 'day').format('dddd M-D-YYYY')}
          </Typography>
        </div>
      </Card>
    );
  }
}

ExperienceCard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.object,
  type: PropTypes.string,
  displayDescription: PropTypes.string,
  displayName: PropTypes.string,
  i: PropTypes.number
};

export default UpcomingExperiences;
