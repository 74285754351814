import _ from 'lodash';

import {Color} from '@tripp/shared';
import BarChart from './BarChart';
import './Stats.scss';

class StackedGroupedBarChart extends BarChart {
  process = (data)=> {
    const {groupKey, subgroupKey, valueKey, queryName} = this.props;

    if (queryName in data) {
      data = data[queryName];
    }

    const xAxisLabels = [];
    const groups = [];
    const subgroups = [];

    for (const {name, items} of data) {
      xAxisLabels.push(name);
      for (const item of items) {
        if (!groups.includes(item[groupKey])) {
          groups.push(item[groupKey]);
        }
        if (!subgroups.includes(item[subgroupKey])) {
          subgroups.push(item[subgroupKey]);
        }
      }
    }

    const datasets = [];
    const stackMap = {};
    let i = 0;

    for (const {items} of data) {
      for (const group of groups) {
        for (const subgroup of subgroups) {
          const item = items.find((el)=> (el[groupKey] === group && el[subgroupKey] === subgroup));
          const value = item ? item[valueKey] : 0;
          const {backgroundColor, borderColor} = Color.nice();

          if (!_.has(stackMap, group)) {
            stackMap[group] = i;
            i += 1;
          }

          const datumExists = datasets.find((elem)=> {
            return (elem.label === `${group} ${subgroup}`) && (elem.stack === stackMap[group]);
          });

          if (!datumExists) {
            datasets.push({
              label: `${group} ${subgroup}`,
              stack: stackMap[group],
              data: [value],
              backgroundColor,
              borderColor,
              borderWidth: 1});
          } else {
            datumExists.data.push(value);
          }
        }
      }
    }

    return {datasets, labels: xAxisLabels};
  };
}

export default StackedGroupedBarChart;
