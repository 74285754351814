import React from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';
import {
  CardElement,
  injectStripe
} from 'react-stripe-elements';
import _ from 'lodash';
import {TextField} from '@rmwc/textfield';
import {Select} from '@rmwc/select';
import {Typography} from '@rmwc/typography';
import {Checkbox} from '@rmwc/checkbox';
import {Button} from '@rmwc/button';
import {LinearProgress} from '@rmwc/linear-progress';

import {
  AsyncButton,
  AuthenticationForm,
  CountryRegionChooser,
  session
} from '@tripp/shared';
import BillingOption from './BillingOption';
import ShippingMethods from './ShippingMethods';
import {
  createHeadsetOrder,
  updateHeadsetOrder,
  createBilling,
  findCompanyByAccessCode
} from './BillingGQL';

const Mode = {
  Access: 'Access',
  Select: 'Select',
  Shipping: 'Shipping',
  Signin: 'Signin',
  Billing: 'Billing',
  Updating: 'Updating',
  Complete: 'Complete'
};

class BillingForm extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      mode: Mode.Select,
      option: null,
      headsetOrder: null,
      billing: null,
      error: '',
      name: '',
      accessCode: '',
      companyName: '',
      shippingAddress: this.blankAddress(),
      billingAddress: this.blankAddress(),
      useShippingForBilling: true
    };
  }

  componentDidMount () {
    const state = session.getAuthRedirectState();
    if (state) {
      this.setState(state);
    }
  }

  render () {
    const {mode, error} = this.state;

    let $error = '';
    if (error) {
      $error = (
        <Typography
          use="headline6"
          className="BillingError"
        >
          {error.message}
        </Typography>
      );
    }

    return (
      <div className="BillingForm">
        {this[`render${mode}Mode`]()}
        {$error}
      </div>
    );
  }

  renderAccessMode () {
    const {accessCode} = this.state;

    return (
      <this.BillingStep
        header="Please enter your company access code"
        selected={false}
      >
        <div className="FormField">
          <TextField
            label="Access Code"
            value={accessCode}
            onChange={this.onChange('accessCode')}
          />
        </div>
        <AsyncButton
          action={this.verifyAccessCode}
          gradient={true}
        >
          Continue
        </AsyncButton>
      </this.BillingStep>
    );
  }

  renderSelectMode () {
    const {options} = this.props;

    const $billingOptions = options.map((option, index)=> {
      return (
        <BillingOption
          key={index}
          option={option}
          onSelect={this.optionSelected}
        />
      );
    });

    return (
      <this.BillingStep
        header="Please select your plan"
        selected={false}
      >
        <div className="BillingOptions">
          {$billingOptions}
        </div>
      </this.BillingStep>
    );
  }

  renderSigninMode () {
    const {router} = this.props;

    return (
      <this.BillingStep
        header="Please signin"
        selected={true}
      >
        <AuthenticationForm
          session={session}
          router={router}
          allowSignup={true}
          redirect={false}
        />
      </this.BillingStep>
    );
  }

  renderShippingMode () {
    const {name} = this.state;

    return (
      <this.BillingStep
        header="Please enter your shipping details"
        selected={true}
      >
        <div className="FormField">
          <TextField
            label="Name"
            value={name}
            onChange={this.onChange('name')}
          />
        </div>
        {this.renderAddressFields('shipping')}
        <AsyncButton
          action={this.createOrder}
          disabled={!this.canCreateOrder()}
          gradient={true}
        >
          Next
        </AsyncButton>
      </this.BillingStep>
    );
  }

  renderBillingMode () {
    const {name} = this.state;

    const style = {
      base: {
        color: 'rgb(175, 166, 204)',
        fontSize: '16px'
      }
    };

    return (
      <this.BillingStep
        header="Please enter your billing details"
        selected={true}
      >
        {this.renderLineItems()}
        {this.renderShippingOptions()}
        <div className="FormField">
          <TextField
            label="Name"
            value={name}
            onChange={this.onChange('name')}
          />
        </div>
        <CardElement style={style}/>
        {this.renderAddressFields('billing')}
        <AsyncButton
          action={this.createBilling}
          disabled={!this.canCreateBilling()}
          gradient={true}
        >
          Purchase
        </AsyncButton>
      </this.BillingStep>
    );
  }

  renderUpdatingMode () {
    return (
      <this.BillingStep
        header="Your order is updating"
        selected={true}
      >
        <LinearProgress />
      </this.BillingStep>
    )
  }

  renderCompleteMode () {
    const {billing} = this.state;
    return (
      <this.BillingStep
        header="You did it!"
        selected={false}
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/5mLGJsxhCHk"
          frameborder="0"
          allowfullscreen
        />
        <h5>dat billing</h5>
        <pre>{JSON.stringify(billing, null, 1)}</pre>
      </this.BillingStep>
    );
  }

  BillingStep = (props)=> {
    const {children, header, selected = true} = props;
    const {option} = this.state;

    let $selected = '';
    if (selected)  {
      $selected = (
        <div className="SelectedBillingOption">
          <BillingOption
            option={option}
          />
          <Button
            className="Button"
            raised
            onClick={(evt)=> {
              evt.preventDefault();
              this.setState({
                option: null,
                mode: Mode.Select
              });
            }}
          >
            Change Plan
          </Button>
        </div>
      );
    }

    return (
      <div className="BillingStep">
        <Typography
          use="headline6"
          className="Header"
        >
          {header}
        </Typography>
        <div className="BillingColumns">
          <div className="DetailsContainer">
            {children}
          </div>
          {$selected}
        </div>
      </div>
    );
  };

  renderLineItems () {
    const {option, headsetOrder} = this.state;
    const {amount: subscriptionPrice, interval} = option.stripePlan;

    const items = [
      {
        name: "TRIPP Subscription",
        price: subscriptionPrice,
        interval
      }
    ];

    if (headsetOrder) {
      const {stripeOrder} = headsetOrder;
      for (const {description: name, amount: price} of stripeOrder.items) {
        items.push({name, price});
      }
    }

    const $items = items.map(({name, price, interval})=> {
      price = `$${price/100}`;
      if (interval) {
        price = `${price}/${interval}`;
      }
      return (
        <tr
          key={name}
          className="LineItem"
        >
          <td className="LineItemDescription">
            {name}
          </td>
          <td className="LineItemPrice">
            {price}
          </td>
        </tr>
      );
    });

    const total = items.reduce((total, {price})=> {
      return total + price;
    }, 0);

    return (
      <table className="LineItems">
        <tbody>
          {$items}
          <tr className="Total">
            <td className="TotalDescription">
              TOTAL
            </td>
            <td className="TotalPrice">
              {`$${total /  100}`}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderShippingOptions () {
    let $shipping = '';
    const {headsetOrder} = this.state;
    if (headsetOrder) {
      const {stripeOrder} = headsetOrder;
      $shipping = (
        <ShippingMethods
          order={stripeOrder}
          onChange={this.onShippingMethodChange}
        />
      );
    }
    return $shipping;
  }

  renderAddressFields (type) {
    const address = this.getAddress(type);
    const attrs = Object.keys(address);

    const renderField = (attr)=> {
      const value = address[attr];
      const label = _.capitalize(attr);
      return (
        <div
          className="FormField"
          key={attr}
        >
          <TextField
            label={label}
            value={value}
            onChange={this.onAddressChange({type, attr})}
          />
        </div>
      );
    };

    let $fields = ['line1', 'line2', 'city', 'zip'].map(renderField);

    const {country, state} = address;
    $fields.push(
      <CountryRegionChooser
        key="CountryRegion"
        country={country}
        region={state}
        onChangeCountry={this.onAddressChange({type, attr: 'country'})}
        onChangeRegion={this.onAddressChange({type, attr: 'state'})}
      />
    );

    let $checkbox = '';
    if (type === 'billing') {
      // If they don't order a headset don't show the checkbox since we
      // don't have a shipping address
      const {headsetOrder} = this.state;
      if (headsetOrder) {
        const {useShippingForBilling} = this.state;
        if (useShippingForBilling) {
          $fields = '';
        }
        $checkbox = (
          <Checkbox
            className="UseShippingForBilling"
            onChange={this.toggleUseShippingForBilling}
            checked={useShippingForBilling}
          >
            Use shipping address for billing
          </Checkbox>
        );
      }
    }

    return (
      <div className="AddressFields">
        {$checkbox}
        {$fields}
      </div>
    );
  }

  canCreateOrder () {
    const {name} = this.state;
    if (!name) {
      return false;
    }
    return this.validAddress('shipping');
  }

  canCreateBilling () {
    // Stripe validation happens as part of the mutation
    // Ideally we would have a way to check whether the given components
    // are valid but the stripe react elements docs don't mention a way
    // of getting a ref and checking this
    const {name, option, headsetOrder, useShippingForBilling} = this.state;
    if (!(name && option)) {
      return false;
    }

    if (!headsetOrder || !useShippingForBilling) {
      return this.validAddress('billing');
    }

    return true;
  }

  // TODO: do a better job of this validation?
  validAddress (type) {
    const fields = ['line1', 'city', 'state', 'zip', 'country'];
    const address = this.getAddress(type);
    return fields.every((field)=> address[field].length > 0);
  }

  blankAddress () {
    return {
      line1: '',
      line2: '',
      city: '',
      country: '',
      state: '',
      zip: ''
    };
  }

  getAddress (type) {
    return this.state[`${type}Address`];
  }

  optionSelected = (option)=> {
    const loggedIn = !!session.account;
    const {stripeProduct} = option;
    const mode = stripeProduct ? Mode.Shipping : Mode.Billing;
    const loggedInState = {option, mode};

    if (loggedIn) {
      this.setState(loggedInState);
    } else {
      session.setAuthRedirect({
        path: '/billing/create',
        state: {...this.state, ...loggedInState}
      });
      this.setState({option, mode: Mode.Signin});
    }
  };

  verifyAccessCode = async ()=> {
    const {client} = this.props;
    const {accessCode} = this.state;
    const result = await client.query({
      query: findCompanyByAccessCode,
      variables: {accessCode}
    });
    const company = result.data.findCompanyByAccessCode;
    if (company) {
      this.setState({error: null, mode: Mode.Select, company});
    } else {
      const error = new Error('Invalid access code');
      this.setState({error});
    }
  };

  createOrder = async ()=> {
    const {client} = this.props;
    const {name, option} = this.state;
    const address = this.getAddress('shipping');
    const {stripeProduct} = option;
    const stripeSkuId = stripeProduct.skus[0].id;

    try {
      const result = await client.mutate({
        mutation: createHeadsetOrder,
        variables: {
          input: {name, stripeSkuId, address}
        }
      });
      const headsetOrder = result.data.createHeadsetOrder;
      this.setState({
        mode: Mode.Billing,
        headsetOrder
      });
    } catch (error) {
      console.error(error);
      this.setState({error: error.message});
    }
  };

  onShippingMethodChange = (method)=> {
    const {id: shippingMethodId} = method;
    let {headsetOrder} = this.state;
    const {client} = this.props;

    try {
      this.setState({
        mode: Mode.Updating
      }, async ()=> {
        const result = await client.mutate({
          mutation: updateHeadsetOrder,
          variables: {
            id: headsetOrder.id,
            input: {shippingMethodId}
          }
        });
        headsetOrder = result.data.updateHeadsetOrder;
        this.setState({
          mode: Mode.Billing,
          headsetOrder
        });
      });
    } catch (error) {
      console.error(error);
      this.setState({error: error.message});
    }
  };

  // testing:
  // card: 4242 4242 4242 4242
  // CVC: random three-digits
  // expiration: any date in the future,
  // zip: random five-digit U.S. ZIP code.
  createBilling = async ()=> {
    const {client} = this.props;
    const {
      name,
      headsetOrder,
      useShippingForBilling,
      option
    } = this.state;

    const {token, error} = await this.createStripeToken();
    if (error) {
      this.setState({error});
      return;
    }
    const {id: stripeToken} = token;

    let billingAddress = this.getAddress('billing');
    if (useShippingForBilling) {
      billingAddress = this.getAddress('shipping');
    }

    const {stripePlanId} = option;
    const {id: headsetOrderId} = headsetOrder;

    try {
      const result = await client.mutate({
        mutation: createBilling,
        variables: {
          input: {
            name,
            stripeToken,
            stripePlanId,
            headsetOrderId
          }
        }
      });
      const billing = result.data.createBilling;
      this.setState({
        billing,
        mode: Mode.Complete
      });
    } catch (error) {
      console.error(error);
      this.setState({error: error.message});
    }
  };

  createStripeToken () {
    const {stripe} = this.props;
    const {name} = this.state;

    const {billingAddress} = this.state;
    const address = _.mapKeys(billingAddress, (key)=> {
      return `address_${_.snakeCase(key)}`;
    });

    return stripe.createToken({
      type: 'card',
      name,
      ...address
    });
  }

  toggleUseShippingForBilling = (event)=> {
    event.preventDefault();
    let {useShippingForBilling} = this.state;
    useShippingForBilling = !useShippingForBilling;
    this.setState({useShippingForBilling});
  }

  onChange = (attr)=> {
    return (event)=> {
      event.preventDefault();
      this.setState({
        [attr]: event.target.value
      });
    };
  };

  onAddressChange = ({type, attr})=> {
    return (event)=> {
      if (event.preventDefault) {
        event.preventDefault();
      }
      const typeAttr = `${type}Address`;
      const address = this.state[typeAttr];
      address[attr] = event.target.value
      this.setState({
        [typeAttr]: address
      });
    };
  }
}

BillingForm.propTypes = {
  client: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired
};

export default injectStripe(withApollo(BillingForm));
