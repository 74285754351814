import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Moment from 'moment';
import {Card, CardAction, CardActions} from '@rmwc/card';
import {ListDivider} from '@rmwc/list';
import {Icon} from '@rmwc/icon';
import {Typography} from '@rmwc/typography';

import {QueryAndRender} from '@tripp/shared';
import {statsKPI} from './StatsQueries';

class StatsChip extends React.Component {
  render () {
    const {company} = this.props;
    const {id: companyId} = company;
    const variables = {
      start: Moment().subtract({days: 14}).format(),
      end: Moment().format(),
      summary: true,
      companyId
    };

    return (
      <QueryAndRender
        className="KPI"
        query={statsKPI}
        variables={variables}
        process={this.process}
        renderLoaded={this.renderLoaded}
      />
    );
  }

  process (data) {
    return Object.entries(data.statsKPI).filter(([key])=> {
      return !(key === '__typename');
    });
  }

  renderLoaded = (rawData)=> {
    const {router, company} = this.props;
    const data = rawData.reduce((acc, [key, val])=> {
      acc[key] = val;
      return acc;
    }, {});
    const {activeUsersCount, runStartCount, moodShiftAvg, ratingAvg} = data;
    const moodShift = _.round((moodShiftAvg * 100), 2);
    const rating = _.round(ratingAvg, 2);
    const quote = `
      In the last 14 days, ${activeUsersCount} users had
      ${runStartCount} runs with an average mood shift of ${moodShift}%.
      The average rating was ${rating} out of 5.
    `;
    return (
      <Card className="StatsChip" style={{width: '375px'}}>
        <Typography
          use="body1"
          tag="div"
          style={{padding: '0.5rem 1rem'}}
          theme="text-secondary-on-background"
        >
        {quote}
        </Typography>
        <ListDivider />
        <CardActions fullBleed>
          <CardAction onClick={()=> router.go(`/company/${company.id}`)}>
            View Stats for {company.name} <Icon icon="arrow_forward" />
          </CardAction>
        </CardActions>
      </Card>
    );
  }
}

StatsChip.propTypes = {
  router: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired
};

export default StatsChip;
