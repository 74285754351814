import React, {Component} from 'react';
//import PropTypes from 'prop-types';

import './Stats.scss';
import GroupedBarChart from './GroupedBarChart';

class MoodImprovement extends Component {
  render () {
    return (
      <div className="MoodImprovement">
        <GroupedBarChart
          className="MoodImprovement"
          queryName="statsMoodImprovement"
          variables={this.props}
          valueKey="tally"
          labelKey="result"
        />
      </div>
    );
  }
}

export default MoodImprovement;
