import gql from 'graphql-tag';
import {giftFragment} from '../Gift/GiftQueries';

const getCompany = gql`
  query getCompany ($id: FirestoreID!) {
    getCompany(id: $id) {
      id
      name
      gifts {
        ${giftFragment}
      }
      seatLocations
      seatGroups
    }
  }
`;

const mixpanelExport = gql`
  query mixpanelExport ($fromDate: Moment!, $toDate: Moment!, $event: [String], $where: String) {
    mixpanelExport (fromDate: $fromDate, toDate: $toDate, event: $event, where: $where)
}`;


export {
  getCompany,
  mixpanelExport
};
