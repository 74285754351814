import React, {Component} from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';

export default class AccountPage extends Component {
  render () {
    const query = gql`
      {
        version
      }
    `;

    return (
      <Query
        query={query}
      >
        {({loading, error, data})=> {
          if (loading) {
            return <div>Loading.</div>;
          }
          if (error) {
            console.error(error);
            return <div>Error.</div>;
          }

          const {version} = data;

          return (
            <div>
              {version}
            </div>
          );
        }}
      </Query>
    );
  }
}
