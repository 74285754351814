import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import {Page} from '@tripp/shared';
import {getCompany} from './CompanyQueries';
import {StatsControls} from '../Stats';
import AllStats from '../Stats/AllStats'; // derp cycles
import './Company.scss';

class CompanyDashboardPage extends React.Component {
  constructor (props) {
    super(props);
    const now = Moment();
    this.state = {
      start: now.clone().subtract({days: 7}),
      end: now,
      filters: []
    };
  }

  render () {
    const {id} = this.props.params;

    return (
      <Page
        className="CompanyDashboardPage"
        query={getCompany}
        variables={{id}}
      >
        {({getCompany: company})=> {
          const {start, end, filters} = this.state;

          return (
            <div className="Stats">
              <StatsControls
                company={company}
                start={start}
                end={end}
                filters={filters}
                onChange={(state)=> {
                  this.setState(state);
                }}
              />
              <AllStats
                companyId={id}
                start={start}
                end={end}
                filters={filters}
              />
            </div>
          );
        }}
      </Page>
    );
  }

  // TODO: Should find root cause for this.. I think has to do with menu
  // toggling triggering props change to router or route
  shouldComponentUpdate (nextProps) {
    const {props} = this;
    return (nextProps.params.id !== props.paramsId);
  }
}

CompanyDashboardPage.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  paramsId: PropTypes.string
};

export default CompanyDashboardPage;
