import ChartJs from 'chart.js';

let index = 0;

// order of these colors determines what happens in .nice
const MaterialColors = {
  Blue: '#2962FF',
  DeepOrange: '#DD2C00',
  Teal: '#00BFA5',
  DeepPurple: '#6200EA',
  Yellow: '#FFD600',
  Red: '#D50000',
  Green: '#00C853',
  Pink: '#C51162',
  Cyan: '#00B8D4',
  Indigo: '#304FFE',
  LightGreen: '#64DD17',
  Purple: '#AA00FF',
  Orange: '#FF6D00',
  LightBlue: '#0091EA',
  Amber: '#FFAB00',
  Lime: '#AEEA00'
};

class Color {
  // TODO: use constructor bleh
  // constructor ({name, hex, rgb}) {
  //
  // }

  static random () {
    const rand = Math.random().toString(16);
    const hex = `${rand}0000000`.slice(2, 8);
    return `#${hex}`;
  }

  static alphafy ({alpha, color}) {
    return ChartJs.helpers.color(color).alpha(alpha).rgbString();
  }

  static nice () {
    const colors = Object.values(MaterialColors);
    const borderColor = colors[index];
    index = ((index + 1) % colors.length);
    const backgroundColor = this.alphafy({color: borderColor, alpha: 0.5});
    return {backgroundColor, borderColor};
  }

  static name (name) {
    const borderColor = MaterialColors[name];
    const backgroundColor = this.alphafy({color: borderColor, alpha: 0.5});
    return {backgroundColor, borderColor};
  }
}

export default Color;
