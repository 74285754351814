import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import _ from 'lodash';
import {withApollo} from 'react-apollo';

import {
  ImageUploader,
  Enums,
  Page,
  session
} from '@tripp/shared';

import './Feed.scss';

const listGifts = gql`
  query listGifts ($personaId: CompoundID!) {
    listGifts {
      id
      media {
        uuid
        ... on Image {
          sizes {
            type
            width
            height
            url
          }
        }
      }
      personaLikes (personaId: $personaId)
    }
  }
`;

const togglePersonaLikeGift = gql`
  mutation togglePersonaLikeGift ($giftId: FirestoreID!, $personaId: CompoundID!) {
    togglePersonaLikeGift(giftId: $giftId, personaId: $personaId)
  }
`;

const createPersonaGifts = gql`
  mutation createPersonaGifts ($input: GiftsCreateInput!) {
    createGifts(input: $input) {
      id
    }
  }
`;

class FeedPage extends React.Component {
  render () {
    const personaId = session.personaId();
    return (
      <Page
        className="FeedPage"
        query={listGifts}
        variables={{personaId}}
      >
        {({listGifts: gifts})=> {
          return (
            <div className="Gifts">
              {this.renderUploader()}
              {this.renderGifts(gifts)}
            </div>
          );
        }}
      </Page>
    );
  }

  renderUploader () {
    const personaId = session.personaId();
    return (
      <ImageUploader
        mutation={createPersonaGifts}
        variables={(images)=> {
          return {
            input: {
              accessType: Enums.GiftAccessType.Public,
              gifterType: Enums.GifterType.Persona,
              gifterId: personaId,
              medias: images
            }
          };
        }}
        refetchQueries={[
          {query: listGifts, variables: {personaId}}
        ]}
      />
    );
  }

  renderGifts (gifts) {
    return gifts.map((gift)=> {
      const {id, media, personaLikes} = gift;
      // TODO: handle non-images
      const url = _.get(media, 'sizes[0].url');
      const label = personaLikes ? 'Unlike' : 'Like';
      return (
        <div key={id} className="Gift">
          <img
            src={url}
            alt="A gifty gift"
          />
          <button onClick={this.toggleLike(id)}>{label}</button>
        </div>
      );
    });
  }

  toggleLike = (giftId)=> {
    return async (evt)=> {
      evt.preventDefault();
      const {client} = this.props;
      const personaId = session.personaId();
      await client.mutate({
        mutation: togglePersonaLikeGift,
        variables: {giftId, personaId},
        refetchQueries: [
          {query: listGifts, variables: {personaId}}
        ]
      });
    };
  }
}

FeedPage.propTypes = {
  client: PropTypes.object.isRequired
};

export default withApollo(FeedPage);
