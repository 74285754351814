import gql from 'graphql-tag';

import {giftFragment} from '../Gift/GiftQueries';

function personaQuery (withGifts) {
  let giftsQuery = '';
  if (withGifts) {
    giftsQuery = `
      gifts {
        ${giftFragment}
      }
    `;
  }

  return gql`
    query getPersona ($id: CompoundID!) {
      getPersona (id: $id) {
        id
        username
        displayName
        mantra
        avatar {
          id
          sizes {
            url
            width
            height
          }
        }
        ${giftsQuery}
      }
    }
  `;
}

const getPersona = personaQuery({gifts: false});
const getPersonaWithGifts = personaQuery({gifts: true});

const updatePersona = gql`
  mutation updatePersona ($id: CompoundID!, $input: PersonaUpdateInput!) {
    updatePersona(id: $id, input: $input) {
      username
    }
  }
`;

const deletePersonaAvatar = gql`
  mutation deletePersonaAvatar ($id: CompoundID!) {
    deletePersonaAvatar(id: $id) {
      deleted
    }
  }
`;

export {
  getPersona,
  getPersonaWithGifts,
  updatePersona,
  deletePersonaAvatar
};
