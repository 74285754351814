import React from 'react';
import PropTypes from 'prop-types';

import {QueryAndRender} from '@tripp/shared';
import * as Queries from './StatsQueries';
import Chart from './Chart';

class QueryAndChart extends React.Component {
  render () {
    const {queryName} = this.props;
    const query = Queries[queryName];

    return (
      <QueryAndRender
        {...this.props}
        query={query}
        renderLoaded={this.renderLoaded}
      />
    );
  }

  renderLoaded = (data)=> {
    const {type, className, options} = this.props;

    return (
      <div className={`${className} ChartWrapper`}>
        <Chart {...{type, data, options}}/>
      </div>
    );
  };
}

QueryAndChart.propTypes = {
  className: PropTypes.string,
  group: PropTypes.string,
  interval: PropTypes.string,
  options: PropTypes.object,
  queryName: PropTypes.string,
  type: PropTypes.string
};

export default QueryAndChart;
