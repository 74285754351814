import React, {Component} from 'react';
//import PropTypes from 'prop-types';
import _ from 'lodash';

import QueryAndChart from './QueryAndChart';
import './Stats.scss';
import MoodColors from './MoodColors';

class MoodMatrix extends Component {
  render () {
    const variables = this.props;
    return (
      <QueryAndChart
        className="MoodMatrix"
        queryName="statsMoodMatrices"
        variables={variables}
        process={this.process}
        type="radar"
      />
    );
  }

  process (data) {
    const labels = [
      'Inspired',
      'Focused',
      'Content',
      'Calm',
      'Relaxed',
      'Bored',
      'Distracted',
      'Worried',
      'Tense',
      'Upset'
    ];

    const datasets = Object.entries(data).map(([source, cloud])=> {
      cloud = _.sortBy(cloud, ({mood})=> labels.indexOf(mood));
      const totals = cloud.map(({total})=> total);
      return {
        ...MoodColors[source],
        label: source,
        data: totals
      };
    });

    return {datasets, labels};
  }
}

export default MoodMatrix;
