import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card} from '@rmwc/card';
import {List, ListItem} from '@rmwc/list';
import {Checkbox} from '@rmwc/checkbox';

class Filter extends Component {
  render () {
    const {label, values, selected} = this.props;

    const $items = values.map((value)=> {
      const checked = selected.includes(value);
      return (
        <ListItem
          key={value}
        >
          <Checkbox
            onChange={this.toggle(value)}
            checked={checked}
          >
            {value}
          </Checkbox>
        </ListItem>
      );
    });

    return (
      <Card
        outlined
        className="Filter"
      >
        <label
          className="FilterLabel mdc-floating-label mdc-floating-label--float-above"
        >
          {label}
        </label>
        <List>
          {$items}
        </List>
      </Card>
    );
  }

  toggle = (value)=> {
    return ()=> {
      const {type, selected} = this.props;
      const values = selected.filter((v)=> v !== value);
      if (!selected.includes(value)) {
        values.push(value);
      }
      this.props.onChange({type, values});
    };
  };
}

Filter.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.array,
  selected: PropTypes.array,
  onChange: PropTypes.func
};

export default Filter;
