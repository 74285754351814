import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {session} from '@tripp/shared';
import StatsChip from '../Stats/StatsChip';
import UpcomingExperiences from './UpcomingExperiences';
import './Home.scss';

class HomePage extends Component {
  render () {
    const {router} = this.props;

    let $statsPrompt = '';
    if (session.isCompanyAdmin()) {
      const {company} = session.account;
      $statsPrompt = (
        <StatsChip router={router} company={company} />
      );
    }

    return (
      <div className="Page HomePage">
        {$statsPrompt}
        <UpcomingExperiences type="Focus" days={4}/>
      </div>
    );
  }
}

HomePage.propTypes = {
  router: PropTypes.object.isRequired
};

export default HomePage;
