import React from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';
import {Button, ButtonIcon} from '@rmwc/button';
import {Icon} from '@rmwc/icon';

import {ImageUploader} from '@tripp/shared';
import {
  createGifts,
  deleteGifts
} from './GiftQueries';


import './Gift.scss';

class Gifts extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      selected: [],
      deleting: false
    };
  }

  render () {
    const {selected, deleting} = this.state;
    const {id, gifts} = this.props.gifter;
    const {gifterType, accessType, getGifter} = this.props;

    let $delete = '';
    const numSelected = selected.length;
    if (numSelected > 0) {
      $delete = (
        <div className="GiftsDelete">
          <Button
            raised
            disabled={deleting}
            className="GiftsDeleteButton"
            onClick={this.deleteSelected}
          >
            <ButtonIcon>delete_forever</ButtonIcon>
            Delete {numSelected} Images
          </Button>
        </div>
      );
    }

    return (
      <div className="Gifts">
        <ImageUploader
          mutation={createGifts}
          variables={(images)=> {
            return {
              input: {
                accessType,
                gifterType,
                gifterId: id,
                medias: images
              }
            };
          }}
          refetchQueries={[
            {query: getGifter, variables: {id}}
          ]}
        />
        <div className="GiftList">
          {this.renderGifts(gifts)}
        </div>
        {$delete}
      </div>
    );
  }

  isSelected (id) {
    const {selected} = this.state;
    return selected.includes(id);
  }

  toggleSelected (id) {
    let {selected} = this.state;
    if (this.isSelected(id)) {
      selected = selected.filter((existingId)=> id !== existingId);
    } else {
      selected.push(id);
    }
    this.setState({selected});
  }

  renderGifts (gifts) {
    return gifts.map((gift)=> {
      // TODO: support other media types?
      const {id, media} = gift;
      const {url} = media.sizes[0];

      const iconClassNames = ['GiftSelect'];
      if (this.isSelected(id)) {
        iconClassNames.push('Selected');
      }

      return (
        <div
          key={id}
          className="Gift"
          onClick={()=> this.toggleSelected(id)}
        >
          <img
            src={url}
            alt="A gifty gift"
          />
          <Icon
            className={iconClassNames.join(' ')}
            strategy="ligature"
            icon="check_box"
          />
        </div>
      );
    });
  }

  deleteSelected = async (event)=> {
    event.preventDefault();
    const {client, gifter, getGifter} = this.props;
    const {selected: ids} = this.state;

    const {id} = gifter;

    this.setState({deleting: true});
    await client.mutate({
      mutation: deleteGifts,
      variables: {ids},
      refetchQueries: [
        {query: getGifter, variables: {id}}
      ]
    });
    this.setState({deleting: false, selected: []});
  }
}

Gifts.propTypes = {
  client: PropTypes.object.isRequired,
  gifter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    gifts: PropTypes.array.isRequired
  }),
  getGifter: PropTypes.object.isRequired,
  accessType: PropTypes.string,
  gifterType: PropTypes.string
};

export default withApollo(Gifts);
