const MoodColors = {
  Opening: {
    backgroundColor: 'rgb(152,251,152, 0.1)',
    borderColor: 'rgb(152,251,152)'
  },
  Closing: {
    backgroundColor: 'rgb(175, 166, 204, 0.1)',
    borderColor: 'rgb(175, 166, 204)'
  }
};

export default MoodColors;
